var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-card', {
          attrs: {
            "id": "document-edit",
            "no-body": ""
          }
        }, [_c('b-card-title', {
          staticClass: "m-1"
        }, [_c('div', {
          staticClass: "d-flex-center justify-content-between"
        }, [_vm.readonly ? _c('div', {
          staticClass: "d-flex-center"
        }, [_c('feather-icon', {
          staticClass: "mx-50",
          attrs: {
            "icon": "FolderMinusIcon",
            "size": "24"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('document.docDetail')))])], 1) : _c('div', {
          staticClass: "d-flex-center"
        }, [!_vm.isMobileView ? _c('feather-icon', {
          staticClass: "mx-50",
          attrs: {
            "icon": "EditIcon",
            "size": "24"
          }
        }) : _vm._e(), _c('span', {
          class: _vm.isMobileView ? 'text-nowrap' : ''
        }, [_vm._v(_vm._s(_vm.$t('document.docEdit')))])], 1), _vm.canModify ? _c('div', {
          class: _vm.isMobileView ? 'd-flex flex-wrap justify-content-end' : ''
        }, [!_vm.readonly ? _c('b-button', {
          staticClass: "mx-md-50 py-50 py-md-1 mt-50 mt-md-0",
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": _vm.confirmDelete
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "TrashIcon",
            "size": "16"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('delete')) + " ")], 1) : _vm._e(), _vm.readonly ? _c('b-button', {
          staticClass: "ml-50 py-50 py-md-1 mt-50 mt-md-0",
          attrs: {
            "variant": "info"
          },
          on: {
            "click": _vm.handleEdit
          }
        }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('edit')))])]) : _c('b-button', {
          staticClass: "ml-50 py-50 py-md-1 mt-50 mt-md-0",
          attrs: {
            "variant": "outline-info"
          },
          on: {
            "click": _vm.handleCancelEdit
          }
        }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('cancelEdit')))])])], 1) : _vm._e()])]), _vm.documentDataToEdit ? _c('div', {
          staticClass: "mb-1 mx-1"
        }, [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.nextHandle);
            }
          }
        }, [_c('b-card', {
          staticClass: "border",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.infoDocs')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "px-1 py-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('document.docsName'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "documentName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('document.docsName')) + " "), !_vm.readonly ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "documentName",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "disabled": _vm.readonly,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('document.ph.docsName')
                },
                model: {
                  value: _vm.documentDataToEdit.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.documentDataToEdit, "name", $$v);
                  },
                  expression: "documentDataToEdit.name"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('document.Folder'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "documentFolder"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('document.Folder')) + " "), !_vm.readonly ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "options": _vm.folderList,
                  "reduce": function reduce(val) {
                    return val.id;
                  },
                  "clearable": false,
                  "searchable": "",
                  "label": "id",
                  "disabled": _vm.readonly,
                  "filterable": false,
                  "loading": _vm.loading,
                  "placeholder": "Please Select"
                },
                on: {
                  "open": _vm.openFolder,
                  "search": _vm.searchFolder
                },
                scopedSlots: _vm._u([{
                  key: "selected-option",
                  fn: function fn(_ref2) {
                    var name = _ref2.name;
                    return [_c('div', {
                      staticClass: "d-flex align-items-center"
                    }, [_c('span', {
                      staticClass: "d-block text-truncate"
                    }, [_vm._v(" " + _vm._s(name) + " ")])])];
                  }
                }, {
                  key: "option",
                  fn: function fn(_ref3) {
                    var name = _ref3.name;
                    return [_c('div', {
                      staticClass: "d-flex align-items-center"
                    }, [_c('span', {
                      staticClass: "font-weight-bold d-block text-nowrap"
                    }, [_vm._v(" " + _vm._s(name) + " ")])])];
                  }
                }, {
                  key: "spinner",
                  fn: function fn(_ref4) {
                    var loading = _ref4.loading;
                    return [loading ? _c('div', {
                      staticClass: "vs__spinner",
                      staticStyle: {
                        "border-left-color": "rgba(88, 151, 251, 0.71)"
                      }
                    }) : _vm._e()];
                  }
                }, {
                  key: "no-options",
                  fn: function fn(_ref5) {
                    var search = _ref5.search,
                      searching = _ref5.searching,
                      loading = _ref5.loading;
                    return [_vm._v(" Loading... ")];
                  }
                }], null, true),
                model: {
                  value: _vm.documentDataToEdit.parent,
                  callback: function callback($$v) {
                    _vm.$set(_vm.documentDataToEdit, "parent", $$v);
                  },
                  expression: "documentDataToEdit.parent"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext)
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          staticClass: "quill-img-max-width",
          attrs: {
            "cols": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('document.description'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "description"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('document.description')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_vm.readonly ? _c('div', [_c('div', {
                domProps: {
                  "innerHTML": _vm._s(_vm.documentDataToEdit.description)
                }
              })]) : _c('div', [_c('quill-editor', {
                ref: "quillRef",
                attrs: {
                  "options": _vm.editorOption,
                  "disabled": _vm.readonly
                },
                model: {
                  value: _vm.documentDataToEdit.description,
                  callback: function callback($$v) {
                    _vm.$set(_vm.documentDataToEdit, "description", $$v);
                  },
                  expression: "documentDataToEdit.description"
                }
              })], 1), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext)
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "File",
            "rules": "size:".concat(_vm.MAX_SIZE_TO_UPLOAD_FILE)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "file"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "text-heading-5"
                    }, [_vm._v(" " + _vm._s(_vm.$t('document.file')) + " "), !_vm.readonly ? _c('em', [_vm._v("(Dung lượng tối đa: " + _vm._s(_vm.sizeFormatter(_vm.MAX_SIZE_TO_UPLOAD_FILE)) + ")")]) : _vm._e()])];
                  },
                  proxy: true
                }], null, true)
              }, [_vm.readonly ? _c('div', [!(_vm.documentDataToEdit.files && _vm.documentDataToEdit.files.length) ? _c('div', [_c('em', [_vm._v("Không có tệp đính kèm.")])]) : _c('div', _vm._l(_vm.documentDataToEdit.files, function (file, index) {
                return _c('div', {
                  key: index
                }, [_vm._v(" " + _vm._s(index + 1) + ". "), _c('b-link', {
                  directives: [{
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.v-info.window",
                    modifiers: {
                      "hover": true,
                      "v-info": true,
                      "window": true
                    }
                  }],
                  attrs: {
                    "title": file.file
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.confirmDownloadFile(file);
                    }
                  }
                }, [_c('span', [_vm._v(_vm._s(_vm.shortenFileName(file.file, 50)))])])], 1);
              }), 0)]) : _c('div', [_c('div', _vm._l(_vm.fileDocuments, function (fileDocument, indexDocument) {
                return _c('div', {
                  key: indexDocument,
                  staticClass: "font-weight-bold d-flex-center justify-content-start"
                }, [fileDocument.name && fileDocument.size ? _c('span', [_vm._v(_vm._s(indexDocument + 1) + ". " + _vm._s(fileDocument.name) + " (" + _vm._s(_vm.sizeFormatter(fileDocument.size)) + ")")]) : _c('span', [_vm._v(_vm._s(indexDocument + 1) + ". "), _c('b-link', {
                  directives: [{
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.v-info.window",
                    modifiers: {
                      "hover": true,
                      "v-info": true,
                      "window": true
                    }
                  }],
                  attrs: {
                    "title": fileDocument.file
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.confirmDownloadFile(fileDocument);
                    }
                  }
                }, [_c('span', [_vm._v(_vm._s(_vm.shortenFileName(fileDocument.file, 50)))])])], 1), _c('b-button', {
                  staticClass: "p-50 ml-25",
                  attrs: {
                    "variant": "flat-danger"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.handleRemoveFileAdded(indexDocument);
                    }
                  }
                }, [_c('feather-icon', {
                  attrs: {
                    "icon": "XIcon",
                    "size": "14"
                  }
                })], 1)], 1);
              }), 0), _c('div', {
                staticClass: "d-flex mt-25"
              }, [_c('b-form-file', {
                staticClass: "py-0",
                staticStyle: {
                  "z-index": "0"
                },
                attrs: {
                  "id": "file",
                  "multiple": "",
                  "accept": "*",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.newFileToAdd,
                  callback: function callback($$v) {
                    _vm.newFileToAdd = $$v;
                  },
                  expression: "newFileToAdd"
                }
              })], 1)]), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _vm.canModify ? _c('b-card', {
          staticClass: "border mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "no-body": ""
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.docsStatus')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "px-1 py-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', [_c('b-form-group', {
          attrs: {
            "label-for": "isShow"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-heading-5"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.isShowTitle')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "isShow",
            "switch": "",
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.documentDataToEdit.isShow,
            callback: function callback($$v) {
              _vm.$set(_vm.documentDataToEdit, "isShow", $$v);
            },
            expression: "documentDataToEdit.isShow"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.documentDataToEdit.isShow ? _vm.$t('document.forF3') : _vm.$t('document.forF2')))])], 1)])], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', [_c('b-form-group', {
          attrs: {
            "label-for": "isActive"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-heading-5"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.isActiveTitle')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "isActive",
            "switch": "",
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.documentDataToEdit.isActive,
            callback: function callback($$v) {
              _vm.$set(_vm.documentDataToEdit, "isActive", $$v);
            },
            expression: "documentDataToEdit.isActive"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.documentDataToEdit.isActive ? _vm.$t('document.publish') : _vm.$t('document.draft')))])], 1)])], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', [_c('b-form-group', {
          attrs: {
            "label-for": "isPin"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-heading-5"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.isPinTitle')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-checkbox', {
          attrs: {
            "id": "isPin",
            "switch": "",
            "disabled": _vm.readonly
          },
          model: {
            value: _vm.documentDataToEdit.isPin,
            callback: function callback($$v) {
              _vm.$set(_vm.documentDataToEdit, "isPin", $$v);
            },
            expression: "documentDataToEdit.isPin"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.$t('document.isPinTitle')))])], 1)])], 1)], 1), _vm.documentDataToEdit.isPin ? _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('document.priorityPin.title')
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "priorityPinTitle"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-heading-5"
              }, [_vm._v(" " + _vm._s(_vm.$t('document.priorityPin.title')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          attrs: {
            "id": "priorityPinTitle",
            "options": _vm.PRIORITY_PIN,
            "reduce": function reduce(val) {
              return val.key;
            },
            "clearable": false,
            "label": "key",
            "disabled": _vm.readonly,
            "loading": _vm.loading,
            "placeholder": _vm.$t('document.ph.folder')
          },
          scopedSlots: _vm._u([{
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "font-weight-bold d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t("document.priorityPin.".concat(data.key))) + " ")])];
            }
          }, {
            key: "option",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "font-weight-bold d-block text-nowrap"
              }, [_vm._v(" " + _vm._s(_vm.$t("document.priorityPin.".concat(data.key))) + " ")])];
            }
          }], null, true),
          model: {
            value: _vm.documentDataToEdit.priorityPin,
            callback: function callback($$v) {
              _vm.$set(_vm.documentDataToEdit, "priorityPin", $$v);
            },
            expression: "documentDataToEdit.priorityPin"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(_vm.$te("document.priorityPin.desc".concat(_vm.documentDataToEdit.priorityPin)) ? _vm.$t("document.priorityPin.desc".concat(_vm.documentDataToEdit.priorityPin)) : '') + " ")])], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
          staticClass: "d-flex justify-content-center",
          attrs: {
            "cols": "12"
          }
        }, [_c('b-button', {
          staticClass: "mr-1 px-3",
          attrs: {
            "variant": "outline-danger"
          },
          on: {
            "click": _vm.backHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _vm.canModify ? _c('b-button', {
          staticClass: "ml-1 px-3",
          attrs: {
            "variant": "gradient",
            "disabled": _vm.readonly || _vm.loading
          },
          on: {
            "click": _vm.submitHandle
          }
        }, [_vm.loading ? _c('div', [_vm.loading ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t('saving')) + " ")], 1) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")])]) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e()], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }